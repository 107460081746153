import * as singleSpaLayout from 'single-spa-layout';
import { headerApplication, sidebarApplication } from './generic-apps';

const loadingTemplate = `
  <div class="loader-workspace">
    <div class="loader-spinner"></div>
  </div>`;

export default singleSpaLayout.constructRoutes({
  routes: [
    {
      type: 'route',
      path: '/login',
      default: false,
      routes: [
        {
          type: 'application',
          name: '@zitrus/main/z-login',
          loader: loadingTemplate,
        },
      ],
      props: {
        name: 'login (vue 2.6.14)',
        disabled: false,
        hidden: false,
        flex: 8,
      },
    },
    {
      type: 'route',
      path: '/ressus',
      default: false,
      routes: [
        headerApplication,
        {
          type: 'application',
          name: '@zitrus/main/ressus',
          loader: loadingTemplate,
        },
        sidebarApplication,
      ],
      props: {
        name: 'ressus (vue 2.6.14)',
        disabled: false,
        hidden: false,
        flex: 8,
      },
    },
    {
      type: 'route',
      path: '/workspace',
      default: false,
      routes: [
        headerApplication,
        {
          type: 'application',
          name: '@zitrus/main/z-gestao',
          loader: loadingTemplate,
        },
        sidebarApplication,
      ],
      props: {
        name: 'z-gestao (vue 2.6.14)',
        disabled: false,
        hidden: false,
        flex: 8,
      },
    },
    {
      type: 'route',
      path: '/gpl',
      default: false,
      routes: [headerApplication, sidebarApplication],
      props: {
        name: 'login (vue 2.6.14)',
        disabled: false,
        hidden: false,
        flex: 8,
      },
    },
  ],
  base: '/',
  redirects: {
    '/': '/login',
  },
});
