import * as singleSpa from 'single-spa';
import * as singleSpaLayout from 'single-spa-layout';

import routes from './constants/routes';

const applicationsTitle = {
  default: 'Zitrus Workspace',
  '@zitrus/main/z-gestao': 'Zitrus Workspace - Gestão',
  '@zitrus/main/ressus': 'Ressus - Ressarcimento ao SUS',
  '@zitrus/main/gpl': 'GPL - Gestão de planos de saúde',
};

const applications = singleSpaLayout.constructApplications({
  routes,
  loadApp: ({ name }) => System.import(name),
});

const layoutEngine = singleSpaLayout.constructLayoutEngine({
  routes,
  applications,
});

applications.forEach(singleSpa.registerApplication);

layoutEngine.activate();
singleSpa.start();

window.addEventListener(
  'single-spa:before-mount-routing-event',
  (evt: CustomEvent) => {
    const activityRoute = singleSpa.checkActivityFunctions(
      evt.target['location'],
    );

    if (activityRoute?.length === 0) {
      singleSpa.navigateToUrl('/login');
    } else {
      const mainModule =
        activityRoute.filter((app) => app.startsWith('@zitrus/main')) ?? [];

      if (mainModule.length > 0 && applicationsTitle[mainModule[0]]) {
        document.title = applicationsTitle[mainModule[0]];
      } else {
        document.title = applicationsTitle.default;
      }
    }
  },
);

// método para setas propriedades de css
function updateFragmentStyle(target, property, value, isImportant = false) {
  if (target !== null && target !== undefined) {
    target.style.setProperty(property, value, isImportant ? 'important' : '');
  }
}

// métodos para ajustes o conteúdo central com base no header e sidebar
const adjustScreenOnLoad = () => {
  const main =
    singleSpa.getMountedApps().filter((app) => app.startsWith('@zitrus/main'))
      ?.length > 0
      ? document.querySelector(
          'div[id^="single-spa-application:@zitrus/main/"]',
        )
      : document.createElement('div');

  const header = singleSpa.getMountedApps().includes('@zitrus/z-header')
    ? document.querySelector(
        'div[id="single-spa-application:@zitrus/z-header"]',
      )
    : document.createElement('div');

  const sidebar = singleSpa.getMountedApps().includes('@zitrus/z-menu')
    ? document.querySelector('div[id="single-spa-application:@zitrus/z-menu"]')
    : document.createElement('div');

  // ajusta margem do main conforme header
  const headerHeight = Number(header?.clientHeight ?? 0);

  // ajusta distancia da esquerda de acordo com o tamanho inicial do sidebar
  const sidebarWidth = getSidebarWidth(sidebar);

  // ajustes margem superior do conteúdo central
  updateFragmentStyle(main, 'padding-top', `${headerHeight}px`);

  // ajusta margem esquerda do conteúdo central de acordo com tamanho inicial do sidebar
  updateFragmentStyle(main, 'padding-left', `${sidebarWidth}px`);

  updateFragmentStyle(header, 'padding-left', `${sidebarWidth}px`);
  updateMainHeight(header, main, sidebarWidth);

  observerMain.observe(main, {
    childList: true,
    subtree: true,
    attributeFilter: ['class', 'style'],
  });

  // cria um novo observado apenas para o sidebar
  if (
    singleSpa.getMountedApps().includes('@zitrus/z-menu') &&
    sidebar.hasChildNodes()
  ) {
    observerSidebar.observe(sidebar, {
      childList: true,
      subtree: true,
      attributeFilter: ['style'],
    });
  }
};

function getSidebarWidth(sidebarParent: Element) {
  const sidebar =
    sidebarParent.querySelector('nav') ?? document.createElement('div');
  let sidebarWidth: number;

  if (
    sidebar.classList.contains('v-navigation-drawer--open') &&
    !sidebar.classList.contains('v-navigation-drawer--open-on-hover')
  ) {
    sidebarWidth = Number(sidebar?.clientWidth ?? 0);
  } else {
    sidebarWidth = Number(sidebar.getAttribute('mini-variant-width') ?? 0);
  }

  return sidebarWidth;
}

// método que ajuste margem de footer e altura do conteúdo central
async function updateMainHeight(
  header: Element,
  main: Element,
  sidebarWidth: number,
) {
  let mainMargin = 0;
  const drawers = main.querySelectorAll('nav');
  let footersOfDrawers = 0;

  for (let index = 0; index < drawers.length; index++) {
    const footerDrawers = drawers
      .item(index)
      .querySelectorAll('.v-footer.v-footer--fixed');

    footersOfDrawers += footerDrawers ? footerDrawers.length : 0;
  }

  const allFooters = main.querySelectorAll('.v-footer.v-footer--fixed');

  if (allFooters?.length > footersOfDrawers) {
    const footer = allFooters.item(0);

    updateFragmentStyle(footer, 'margin-left', `${sidebarWidth}px`);
    mainMargin += footer.clientHeight;
  }

  const headerHeight = header?.clientHeight ?? 0;
  const appWrap = main.querySelector('.v-application--wrap');

  updateFragmentStyle(
    appWrap,
    'min-height',
    `calc(100vh - ${headerHeight + mainMargin}px)`,
  );
}

// método que ajusta conteúdo central ao haver resizes.
function updateLayoutResize() {
  const main = document.querySelector(
    'div[id^="single-spa-application:@zitrus/main/"]',
  );

  if (main === null || !main || !main.hasChildNodes) {
    observerMain.disconnect();
    return false;
  }

  const header = singleSpa.getMountedApps().includes('@zitrus/z-header')
    ? document.querySelector(
        'div[id="single-spa-application:@zitrus/z-header"] > #z-header',
      )
    : document.createElement('div');

  const sidebar = singleSpa.getMountedApps().includes('@zitrus/z-menu')
    ? document.querySelector(
        'div[id="single-spa-application:@zitrus/z-menu"] > #z-menu',
      )
    : document.createElement('div');

  const sidebarWidth = getSidebarWidth(sidebar);
  const headerHeight = Number(header?.clientHeight ?? 0);

  updateMainHeight(header, main, sidebarWidth);

  const nav = main.querySelector(
    'div[id^="single-spa-application:@zitrus/main/"] .v-application .v-navigation-drawer.v-navigation-drawer--absolute',
  );

  if (nav != null) {
    updateFragmentStyle(
      nav,
      'height',
      `calc(100vh - ${headerHeight ?? 0}px)`,
      true,
    );
  }
}

const observerMain = new MutationObserver(() => {
  updateLayoutResize();
});

const observerSidebar = new MutationObserver(() => {
  const sidebar = document.querySelector(
    'div[id="single-spa-application:@zitrus/z-menu"] > #z-menu',
  );

  if (sidebar == null) {
    observerSidebar.disconnect();
    return false;
  }

  const main = document.querySelector(
    'div[id^="single-spa-application:@zitrus/main/"]',
  );

  const sidebarWidth = getSidebarWidth(sidebar);

  updateFragmentStyle(main, 'padding-left', `${sidebarWidth}px`);

  const footer = document.querySelector('footer');
  if (footer && footer.classList.contains('v-footer--fixed')) {
    updateFragmentStyle(footer, 'margin-left', `${sidebarWidth}px`);
  }
});

const observerDocumentApps = new MutationObserver(() => {
  verifyMountedAppsAndLoadeds();
});

const verifyMountedAppsAndLoadeds = () => {
  const hasHeader = singleSpa.getMountedApps().includes('@zitrus/z-header');
  const hasMenu = singleSpa.getMountedApps().includes('@zitrus/z-menu');
  const hasMainSystem =
    singleSpa.getMountedApps().filter((app) => app.startsWith('@zitrus/main'))
      ?.length > 0;

  const hasHeaderElements = document.querySelector(
    'div[id="single-spa-application:@zitrus/z-header"] > #z-header',
  );
  const hasMenuElements = document.querySelector(
    'div[id="single-spa-application:@zitrus/z-menu"] > #z-menu',
  );
  const hasMainSystemElements = document.querySelector(
    'div[id^="single-spa-application:@zitrus/main/"]',
  );

  adjustScreenOnLoad();

  if (
    (hasHeader && !hasHeaderElements?.hasChildNodes()) ||
    (hasMenu && !hasMenuElements?.hasChildNodes()) ||
    (hasMainSystem && !hasMainSystemElements?.hasChildNodes())
  ) {
    // não faz nada se ainda não estiver 100% carregado.
    observerDocumentApps.disconnect();
  }
};

// iniciar o observador para ajustes de tela quando houver algum tipo de trigger de alteração no single spa
window.addEventListener('single-spa:app-change', () => {
  observerDocumentApps.observe(document, {
    subtree: true,
    attributes: true,
  });
});
